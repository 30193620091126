import React, { useState, useEffect, useContext } from 'react';
import { TextField, Box } from '@mui/material/';
import { LoadingButton } from 'components';
import { useLazyQuery, useMutation, gql } from '@apollo/client';
import { ToastDataContext, AuthDataContext } from 'contexts';

const UserProfileForm = () => {
  const { setToast } = useContext(ToastDataContext);
  const { account, user: loadedUser } = useContext(AuthDataContext);
  const [user, setUser] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(user?.profile?.firstName || '');
  const [lastName, setLastName] = useState(user?.profile?.lastName || '');
  const [email, setEmail] = useState(user?.profile?.email || '');

  const GET_USER_QUERY = gql`
    query GetUserInfo {
      getUser {
        id
        userId
        firstName
        lastName
        email
      }
    }
  `;

  const processData = (dataToProcess) => {
    try {
      const userProfile = dataToProcess.getUser;
      setUser(userProfile);
      setFirstName(userProfile?.firstName || '');
      setLastName(userProfile?.lastName || '');
      setEmail(userProfile?.email || '');
    } finally {
      setLoading(false);
    }
  };

  const [fetchData] = useLazyQuery(GET_USER_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPDATE_USER_MUTATION = gql`
    mutation UpdateUser(
      $id: ID!
      $accountId: ID!
      $email: String
      $firstName: String
      $lastName: String
    ) {
      updateUserInAccount(
        id: $id
        accountId: $accountId
        email: $email
        firstName: $firstName
        lastName: $lastName
      ) {
        id
      }
    }
  `;

  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setToast({ type: 'error', message: e.message });
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  // validate form values
  const validateForm = () => {
    const errors = [];
    if (email.length < 6 || !/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      errors.push({ email: 'Invalid email address format' });
    }
    if (firstName.length > 25) {
      errors.push({ firstName: 'First name must be under 25 characters' });
    }
    if (lastName.length > 25) {
      errors.push({ lastName: 'Last name must be under 25 characters' });
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm();
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setSaving(true);
        updateUser({
          variables: {
            id: user.userId,
            accountId: account.id,
            email,
            firstName,
            lastName,
          },
          onCompleted: () => {
            setSaving(false);
            setToast({ type: 'success', message: 'Profile updated' });
          },
          update(cache) {
            cache.modify({
              id: cache.identify(user),
              fields: {
                email() {
                  return email;
                },
                firstName() {
                  return firstName;
                },
                lastName() {
                  return lastName;
                },
              },
            });
          },
        });
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {formErrors.formError && <Box sx={{ color: 'red' }}>{formErrors.formError}</Box>}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          disabled={loading}
          required
          id="user-profile-firstname"
          data-cy="profile_firstname"
          label="First Name"
          type="text"
          value={firstName || ''}
          onChange={(e) => setFirstName(e.target.value)}
          error={Boolean(formErrors.firstName)}
          helperText={formErrors.firstName}
        />
        <TextField
          disabled={loading}
          required
          id="user-profile-lastname"
          data-cy="profile_lastname"
          label="Last Name"
          type="text"
          value={lastName || ''}
          onChange={(e) => setLastName(e.target.value)}
          error={Boolean(formErrors.lastName)}
          helperText={formErrors.lastName}
        />
        {!loadedUser.profile.federated && (
          <TextField
            disabled={loading}
            required
            id="user-profile-email"
            data-cy="profile_email"
            label="Email"
            type="text"
            value={email || ''}
            onChange={(e) => setEmail(e.target?.value?.trim())}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'center',
          mt: 5,
        }}
      >
        <LoadingButton
          loading={saving}
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
          data-cy="profile_update"
        >
          Update My Profile
        </LoadingButton>
      </Box>
    </form>
  );
};

export default UserProfileForm;
