import React, { useState, useContext } from 'react';
import { Loading, NonScrollable, FilterDropdown } from 'components';
import {
  Box,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
} from '@mui/material';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import GasGauge from 'pages/Dashboard/GasGauge';
import Widget from 'pages/Dashboard/Widget';
import WidgetHeader from 'pages/Dashboard/WidgetHeader';
import AsinTable from '../AsinTable';

const AsinHealth = () => {
  const { hasFeatureEnabled } = useContext(AuthDataContext);
  const [healthIndex, setHealthIndex] = useState(0);
  const [asins, setAsins] = useState([]);
  const [filteredAsins, setFilteredAsins] = useState([]);
  const [asinSummary, setAsinSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const [tiers, setTiers] = useState([]);

  const filterOptions = [
    { value: 'lowest20', title: '20 Lowest Scoring ASINs' },
    { value: 'critical', title: 'Critical ASINs' },
    { value: 'atRisk', title: 'At Risk ASINs' },
    { value: 'good', title: 'Good ASINs' },
    { value: 'excellent', title: 'Excellent ASINs' },
  ];

  const [filter, setFilter] = useState(filterOptions[0].value);

  const filterAsins = (f, asinsToUse) => {
    let asinsToShow = [];
    if (f === 'lowest20') {
      asinsToShow = asinsToUse.slice(0, 20);
    } else if (f === 'critical') {
      asinsToShow = asinsToUse.filter(a => a.currentScore.score <= -50);
    } else if (f === 'atRisk') {
      asinsToShow = asinsToUse.filter(a => a.currentScore.score > -50 && a.currentScore.score < 0);
    } else if (f === 'good') {
      asinsToShow = asinsToUse.filter(a => a.currentScore.score >= 0 && a.currentScore.score < 50);
    } else if (f === 'excellent') {
      asinsToShow = asinsToUse.filter(a => a.currentScore.score > 49);
    }
    setFilteredAsins(asinsToShow);
  }
  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const tierOrder = ['hourly', 'twiceDaily', 'legacy', 'daily'];
      const cleanedUpAsins = dataToProcess.getAsinHealth.filter(
        (a) => a.currentScore.score !== null,
      );
      const average = cleanedUpAsins.reduce((a, b) => a + b.currentScore.score, 0) / cleanedUpAsins.length;
      setHealthIndex(Math.round(average));
      setAsins(cleanedUpAsins);
      filterAsins(filter, cleanedUpAsins);
      setFilteredAsins(cleanedUpAsins.slice(0, 20));
      const sortedSummaryByTier = cloneDeep(dataToProcess.getAsinHealthSummary).toSorted(
        (a, b) => tierOrder.indexOf(a.tierId) - tierOrder.indexOf(b.tierId),
      );
      const rows = [
        { id: 'excellentCount', display: 'Excellent (50 to 100)', bgcolor: '#D9F2D0' },
        { id: 'goodCount', display: 'Good (0 to 49)', bgcolor: '#e6f2ff' },
        { id: 'atRiskCount', display: 'At-Risk (-49 to -1)', bgcolor: '#ffffdd' },
        { id: 'criticalCount', display: 'Critical (-100 to -50)', bgcolor: '#ffdddd' },
        { id: 'totalCount', display: 'Total', bgcolor: 'white' },
      ];
      const newRows = [];
      const tiersToShow = [];
      for (let i = 0; i < sortedSummaryByTier.length; i += 1) {
        const tier = sortedSummaryByTier[i];
        tiersToShow.push(tier.tierName);
      }
      setTiers(tiersToShow);
      for (let i = 0; i < rows.length; i += 1) {
        const row = rows[i];
        for (let j = 0; j < sortedSummaryByTier.length; j += 1) {
          const tier = sortedSummaryByTier[j];
          row[tier.tierName] = tier[row.id];
        }
        newRows.push(row);
      }
      setAsinSummary(newRows);
      setLoading(false);
    }
  };

  const GET_HEALTH_QUERY = gql`
    query GetHealth {
      getAsinHealthSummary {
        id
        tierId
        tierName
        criticalCount
        atRiskCount
        goodCount
        excellentCount
        totalCount
      }
      getAsinHealth {
        id
        asin
        asinUrl
        name
        tier
        tierName
        categoryId
        price
        harvested
        uncontested
        dailySalesEstimate
        offersCount
        createdDate
        parentAsin
        variantTitle
        similarAsin
        imageUrl
        authorizedSellerExists
        currentScore {
          score
          harvestDate
        }
        authWinRate
        lostSales
      }
    }
  `;

  const { refetch } = useQuery(GET_HEALTH_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleChooseFilter = (f) => {
    setFilter(f);
    filterAsins(f, asins);
  };

  return (
    <NonScrollable transparent sx={{ width: '100%', mt: 1, mx: 1 }}>
      {loading && <Loading />}
      {!loading && asinSummary.length > 0 && (
        <Box sx={{ display: 'flex' }}>
          <Widget sx={{ width: '100%' }}>
            <WidgetHeader title="Summary" />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ fontWeight: 700 }}>Score Range</Box>
                    </TableCell>
                    {tiers.map((t) => (
                      <TableCell key={t} width="125px">
                        {hasFeatureEnabled('tiers') && (
                          <Box sx={{ fontWeight: 700, textAlign: 'end' }}>{t}</Box>
                        )}
                        {!hasFeatureEnabled('tiers') && (
                          <Box sx={{ fontWeight: 700, textAlign: 'end' }}>ASINs</Box>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {asinSummary.map((r) => (
                    <TableRow key={r.id}>
                      <TableCell sx={{ bgcolor: r.bgcolor }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', fontWeight: 500 }}>
                          {r.display}
                        </Box>
                      </TableCell>
                      {tiers.map((t) => (
                        <TableCell key={`${r.id}-${t}`} sx={{ bgcolor: r.bgcolor }}>
                          <Box sx={{ fontWeight: 500, textAlign: 'end' }}>
                            {r[t]?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Widget>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <GasGauge loading={loading} title="ASIN Health Index" value={healthIndex} min={-100} max={100} />
          </Box>
        </Box>
      )}
      {!loading && (
        <Widget>
          <WidgetHeader
            title="ASIN List"
            bottom={
              <Box>
                <FilterDropdown
                  label="Filter"
                  items={filterOptions}
                  value={filter}
                  datacy="select_dropdown_option_Filter"
                  onSelect={handleChooseFilter}
                  disableAllOption
                />
              </Box>
            }
          />
          <Box sx={{ overflow: 'scroll', display: 'flex', flexDirection: 'column'}}>
            <AsinTable
              showWinRate
              healthMode
              asins={filteredAsins}
              showTier={hasFeatureEnabled('tiers')}
              onMovedItem={() => refetch()}
            />
          </Box>
        </Widget>
      )}
    </NonScrollable>
  );
};

export default AsinHealth;
