import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
// eslint-disable-next-line camelcase
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ReactComponent as GasGaugeEllipse } from 'assets/gas-gauge-ellipse.svg';
import { Loading } from 'components';
import { Box, useTheme } from '@mui/material';
import WidgetHeader from './WidgetHeader';
import Widget from './Widget';

am5.addLicense('AM5C329389658');

function GasGauge({ title, min, max, value, reverse, percentage, loading, minLabel, maxLabel, labelFontSize }) {
  const theme = useTheme();
  const rangeGrayRef = useRef(null);
  const labelRef = useRef(null);
  const handRef = useRef(null);
  const handDataRef = useRef(null);

  const gradientStops = (() => {
    if (reverse) {
      return [
        { color: am5.color(0x3C7F22) },
        { color: am5.color(0xF1A93B) },
        { color: am5.color(0xEA3524) }
      ];
    }
    return [
      { color: am5.color(0xEA3524) },
      { color: am5.color(0xF1A93B) },
      { color: am5.color(0x3C7F22) }
    ];
  })();

  // Below 3 functions are for determine color for gauge
  // hand relative to where it is on the gradient

  function byteLinear(a, b, x) {
    // eslint-disable-next-line no-bitwise
    const y = (`0x${a}` * (1 - x) + `0x${b}` * x) | 0;
    return y.toString(16).padStart(2, '0'); // hex output
  }

  function linear(s, e, x) {
    const r = byteLinear(s[1] + s[2], e[1] + e[2], x);
    const g = byteLinear(s[3] + s[4], e[3] + e[4], x);
    const b = byteLinear(s[5] + s[6], e[5] + e[6], x);
    return `#${r}${g}${b}`;
  }

  function colorGradient(t, start, middle, end) {
    return t >= 0.5 ? linear(middle, end, (t - 0.5) * 2) : linear(start, middle, t * 2);
  }

  // End of grandient helper functions

  useEffect(() => {
    const root = am5.Root.new(`GasGauge-${title}`);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    const chart = root.container.children.push(
      am5radar.RadarChart.new(root, {
        panX: false,
        panY: false,
        startAngle: -200,
        endAngle: 20,
        height: 84,
        width: 210,
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    const gradient = am5.LinearGradient.new(root, {
      rotation: 0,
      stops: gradientStops
    });

    const axisRenderer = am5radar.AxisRendererCircular.new(root, {
      innerRadius: -1,
    });

    axisRenderer.grid.template.set("forceHidden", true);
    axisRenderer.labels.template.set("forceHidden", true);

    const axis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min,
        max,
        strictMinMax: true,
        renderer: axisRenderer
      })
    );

    // Gradient background
    const rangeDataItem = axis.makeDataItem({
      value: min,
      endValue: max
    });
    axis.createAxisRange(rangeDataItem);
    rangeDataItem.get("axisFill").setAll({
      visible: true,
      strokeGradient: gradient,
      strokeOpacity: 1.0,
      strokeWidth: 2,
      innerRadius: -1,
    });
    rangeDataItem.get("tick").setAll({
      visible: false
    });

    // Gray 'background' that is overwritten over the gradient background
    const rangeDataItemGray = axis.makeDataItem({
      value,
      endValue: max
    });
    rangeGrayRef.current = rangeDataItemGray;
    axis.createAxisRange(rangeDataItemGray);
    rangeDataItemGray.get("axisFill").setAll({
      visible: true,
      stroke: am5.color(theme.palette.greys.lightGrey),
      strokeOpacity: 1.0,
      strokeWidth: 2,
      innerRadius: -1,
    });
    rangeDataItemGray.get("tick").setAll({
      visible: false
    });

    // Hand for the radial graph
    const handDataItem = axis.makeDataItem({});
    handDataRef.current = handDataItem;
    const hand = am5radar.ClockHand.new(root, {
      radius: am5.percent(100),
      topWidth: 2,
      bottomWidth: 2,
      pinRadius: 0,
      innerRadius: am5.percent(60),
    });
    handRef.current = hand;
    handDataItem.set("bullet", am5xy.AxisBullet.new(root, {
      sprite: hand
    }));
    axis.createAxisRange(handDataItem);

    // Value in the center
    const label = am5.Label.new(root, {
      text: '',
      fontSize: 24,
      fontWeight: "400",
      fontFamily: theme.typography.fontFamily,
      textAlign: "center",
      y: am5.percent(50),
      paddingTop: 0,
      height: am5.percent(100),
      width: am5.percent(100),
      paddingBottom: 0
    });
    chart.children.unshift(label);
    labelRef.current = label;

    // Labels for gauge
    chart.children.unshift(am5.Label.new(root, {
      text: minLabel ?? 'LOW',
      fontSize: labelFontSize ?? '8px',
      fontWeight: '700',
      fontFamily: theme.typography.fontFamily,
      textAlign: 'end',
      x: am5.percent(22),
      y: am5.percent(78),
      fill: am5.color(theme.palette.greys.grey),
      paddingTop: 0,
      paddingBottom: 0,
    }));
    chart.children.unshift(am5.Label.new(root, {
      text: maxLabel ?? 'HIGH',
      fontSize: labelFontSize ?? '8px',
      fontWeight: '700',
      fontFamily: theme.typography.fontFamily,
      textAlign: 'start',
      x: am5.percent(78),
      y: am5.percent(78),
      fill: am5.color(theme.palette.greys.grey),
      paddingTop: 0,
      paddingBottom: 0,
    }));

    return () => {
      root.dispose();
    };
  }, []);

  useEffect(() => {
    if (value !== null && rangeGrayRef.current) {
      const color = colorGradient(
        (Math.min(value, max) - min) / (max - min),
        gradientStops[0].color.toCSSHex(),
        gradientStops[1].color.toCSSHex(),
        gradientStops[2].color.toCSSHex(),
      );
      handRef.current.hand.set("fill", am5.color(color));
      labelRef.current.set("fill", am5.color(color));
      labelRef.current.set("text", value !== null ? `${value}${percentage ? '%' : ''}` : '');
      rangeGrayRef.current.set('value', value);
      handDataRef.current.set("value", value);
    }
  }, [value]);

  return (
    <Widget>
      <WidgetHeader title={title} sx={{ pb: 0, minHeight: 0 }} center />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', mb: 1 }}>
        <Box sx={{ display: 'grid', justifyContent: 'center', minHeight: '92px' }}>
          {value !== null && (
            <Box
              sx={{
                gridRowStart: 1,
                gridColumnStart: 1,
                transform: 'translateY(7px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GasGaugeEllipse />
            </Box>
          )}
          <Box
            sx={{
              gridRowStart: 1,
              gridColumnStart: 1,
              height: value !== null ? '92px' : '0px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              id={`GasGauge-${title}`}
              sx={{
                opacity: value !== null ? 1.0 : 0.0,
                pt: 0,
                px: 0.5,
                mb: 0,
                width: '218px',
                height: value !== null ? '192px' : '0px',
                display: 'inline-block',
              }}
            />
          </Box>
          {value === null && loading && <Loading />}
        </Box>
      </Box>
    </Widget>
  );
}

GasGauge.propTypes = {
  title: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number,
  reverse: PropTypes.bool,
  percentage: PropTypes.bool,
  loading: PropTypes.bool,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  labelFontSize: PropTypes.string,
};

GasGauge.defaultProps = {
  reverse: false,
  percentage: false,
  value: null,
  loading: false,
  minLabel: null,
  maxLabel: null,
  labelFontSize: null,
};

export default GasGauge;
