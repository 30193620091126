import React, { useState, useContext } from 'react';
import { Content, Top, Header, Loading } from 'components';
import {
  Tab,
  Box,
  Tooltip,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import { TabList, TabContext, TabPanel } from '@mui/lab';
import AddAsins from 'pages/Common/AddAsins';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { gql, useQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import TierWrapper from './Tiers/TierWrapper';
import Health from './AsinHealth/AsinHealth';

const NoMaxWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

const AsinManager = () => {
  const [tab, setTab] = useState('health');
  const { account, updateAccount, hasFeatureEnabled } = useContext(AuthDataContext);
  const [addAsinOpen, setAddAsinOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [updateChildFunction, setUpdateChildFunction] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setToast } = useContext(ToastDataContext);
  const [tiers, setTiers] = useState([]);

  const handleTabChange = (newValue) => {
    setSelectedCategory(null);
    setTab(newValue);
  };

  const handleUpdateChild = () => {
    if (updateChildFunction) {
      updateChildFunction.fn({});
    }
    updateAccount();
  };

  const handleSetUpdateFunction = (fn) => {
    setUpdateChildFunction({ fn });
  };

  const processCategoryData = (dataToProcess) => {
    if (dataToProcess) {
      const sortedList = dataToProcess.getCategoryList
        .concat()
        .sort((a, b) => a?.name.localeCompare(b?.name));
      setCategories(sortedList);
      setLoading(false);
    }
  };

  const processTierData = (dataToProcess) => {
    if (dataToProcess) {
      const tierOrder = ['hourly', 'twiceDaily', 'legacy', 'daily'];
      const result = cloneDeep(dataToProcess.getTiersForAccount)
        .filter((t) => {
          if (!hasFeatureEnabled('tiers') && t.tier !== 'legacy') {
            return false;
          }
          return true;
        })
        .toSorted((a, b) => tierOrder.indexOf(a.tier) - tierOrder.indexOf(b.tier));

      setTiers(result);
      setLoading(false);
    }
  };

  const GET_TIERS_FOR_ACCOUNT_QUERY = gql`
    query GetTiersForAccount($accountId: Int) {
      getTiersForAccount(accountId: $accountId) {
        id
        tier
        description
        slots
        asinCount
      }
    }
  `;

  useQuery(GET_TIERS_FOR_ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { accountId: account.id },
    skip: !account,
    onCompleted: processTierData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const GET_CATEGORY_LIST_QUERY = gql`
    query GetCategories($tiers: [String]) {
      getCategoryList(tiers: $tiers) {
        id
        name
      }
    }
  `;

  const { refetch } = useQuery(GET_CATEGORY_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: processCategoryData,
    variables: {
      tiers: [tab],
    },
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const handleNewCategoryAdded = (category) => {
    if (updateChildFunction) {
      updateChildFunction.fn({ category });
    }
    updateAccount();
    refetch();
  };

  const handleCategoriesChanged = () => {
    refetch();
  };

  const TierInfo = () => (
    <NoMaxWidthTooltip
      title={
        <Box>
          <TableContainer>
            <Table
              sx={{ fontSize: '13px', fontWeight: 500, maxWidth: '800px', my: '4px', mx: '1px' }}
            >
              <TableBody>
                <TableRow>
                  <TableCell width="30%" sx={{ bgcolor: 'white' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }} data-cy="tier_info_link">
                      <Box sx={{ fontWeight: 700, mb: 0.5 }}>ASIN PROTECTION TIERS</Box>
                      <Box sx={{ fontWeight: 500 }}>
                        <a
                          href="https://support.ipsecure.com/kb/asin-tiering-details"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (learn about ASIN Tiers here)
                        </a>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#D9F2D0', alignContent: 'flex-start' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 700, mb: 0.5 }} data-cy="critical_tier_info">
                        PREMIUM
                      </Box>
                      <Box sx={{ fontWeight: 500 }}>
                        Highest level of protection, deepest visibility, and all seller actions
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#FAE2D5', alignContent: 'flex-start' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 700, mb: 0.5 }} data-cy="active_tier_info">
                        STANDARD
                      </Box>
                      <Box sx={{ fontWeight: 500 }}>
                        High level of protection, deeper visibility, and all seller actions
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#F1CEEE', alignContent: 'flex-start' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 700, mb: 0.5 }} data-cy="maintenance_tier_info">
                        MAINTENANCE
                      </Box>
                      <Box sx={{ fontWeight: 500 }}>Set it, forget it, and get alerted</Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" sx={{ bgcolor: 'white' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ fontWeight: 500, mb: 0.5 }}>DATA REFRESH (approx.)</Box>
                      <Box sx={{ fontWeight: 500 }}>
                        Buy box win rates, pricing, seller, and offer data
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#D9F2D0' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>hourly</Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#FAE2D5' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>6X daily</Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#F1CEEE' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>1X daily</Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" sx={{ bgcolor: 'white' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ fontWeight: 500, mb: 0.5 }}>ACTIONABLE EMAIL ALERTS</Box>
                      <Box sx={{ fontWeight: 500 }}>Real-time and on-demand alerts</Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#D9F2D0' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>premium + standard alerts</Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#FAE2D5' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>premium + standard alerts</Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#F1CEEE' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>standard alerts</Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" sx={{ bgcolor: 'white' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ fontWeight: 500, mb: 0.5 }}>ANALYTICS</Box>
                      <Box sx={{ fontWeight: 500 }}>50+ on-demand and scheduled reports</Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#D9F2D0' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>
                        <CheckRoundedIcon sx={{ color: 'success.main' }} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#FAE2D5' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>
                        <CheckRoundedIcon sx={{ color: 'success.main' }} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#F1CEEE' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500, opacity: 0.5 }}>
                        <ClearRoundedIcon sx={{ color: 'red' }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell width="30%" sx={{ bgcolor: 'white' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Box sx={{ fontWeight: 500, mb: 0.5 }}>SELLER ENFORCEMENTS</Box>
                      <Box sx={{ fontWeight: 500 }}>
                        Brand Registry integration, trackable letters, emails and anonymous test
                        buys
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#D9F2D0' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>
                        <CheckRoundedIcon sx={{ color: 'success.main' }} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#FAE2D5' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500 }}>
                        <CheckRoundedIcon sx={{ color: 'success.main' }} />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell width="20%" sx={{ bgcolor: '#F1CEEE' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Box sx={{ fontWeight: 500, opacity: 0.5 }}>
                        <ClearRoundedIcon sx={{ color: 'red' }} />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
    >
      <InfoIcon sx={{ ml: '4px', width: '13px', height: '13px' }} data-cy="tier_info_mouseover" />
    </NoMaxWidthTooltip>
  );

  return (
    <Content transparent>
      {loading && <Loading />}
      {!loading && tiers.length > 0 && (
        <TabContext value={tab}>
          <Top sx={{ pb: '0' }}>
            <Header
              showAddAsin
              title="ASIN Manager"
              onAddAsin={() => {
                setAddAsinOpen(true);
              }}
              showCredits
              tier={tiers.find((t) => t.tier === tab)}
            >
              <TabList
                sx={{ mt: 2 }}
                color="primary"
                onChange={(_event, value) => handleTabChange(value)}
              >
                <Tab key="health" label="ASIN Health" value="health" data-cy="health" />
                {tiers.length > 0 &&
                  tiers.map((t) => (
                    <Tab
                      key={t.tier}
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {hasFeatureEnabled('tiers') && <Box>{t.description} Tier</Box>}
                          {!hasFeatureEnabled('tiers') && <Box>ASINs</Box>}
                          {hasFeatureEnabled('tiers') && <TierInfo />}
                        </Box>
                      }
                      value={t.tier}
                      data-cy={`${t.tier}_tab`}
                    />
                  ))}
              </TabList>
            </Header>
          </Top>
          <Box sx={{ overflowY: 'auto', display: 'flex', height: '100%' }}>
            <TabPanel
              key="health"
              sx={{
                m: 0,
                p: 0,
                overflow: 'hidden',
                display: 'flex',
                height: '100%',
                width: tab === 'health' ? '100%' : '',
              }}
              value="health"
            >
              <Health />
            </TabPanel>
            {tiers.length > 0 &&
              tiers.map((t) => (
                <TabPanel
                  key={t.tier}
                  sx={{
                    m: 0,
                    p: 0,
                    overflow: 'hidden',
                    display: 'flex',
                    height: '100%',
                    width: tab === t.tier ? '100%' : '',
                  }}
                  value={t.tier}
                >
                  <TierWrapper
                    setUpdateFunction={handleSetUpdateFunction}
                    setSelectedCategory={setSelectedCategory}
                    onCategoriesChanged={handleCategoriesChanged}
                    tier={t}
                    allTiers={tiers}
                  />
                </TabPanel>
              ))}
          </Box>
        </TabContext>
      )}
      {addAsinOpen && (
        <AddAsins
          categories={categories}
          category={selectedCategory}
          isOpen={addAsinOpen}
          addAsinsMode
          tier={tiers.find((t) => t.tier === tab)}
          categoryId={selectedCategory ? selectedCategory.id : null}
          onClose={(result) => {
            if (result?.isNewCategory && result?.category) {
              handleNewCategoryAdded(result?.category);
            } else if (result?.category) {
              handleUpdateChild();
            }
            setAddAsinOpen(false);
          }}
        />
      )}
    </Content>
  );
};

export default AsinManager;
