import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

const NonScrollable = ({ children, transparent, transparentPadding, sx, ...rest }) => {
  const theme = useTheme();
  const padding = transparentPadding
    ? theme.spacing(transparentPadding)
    : theme.spacing(theme.contentPaddingSpacing);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.largeBorderRadius,
        overflow: 'hidden',
        backgroundColor: transparent ? null : 'greys.white',
        p: transparent && !transparentPadding ? 0 : padding,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

NonScrollable.propTypes = {
  sx: PropTypes.oneOfType([PropTypes.object]),
  transparent: PropTypes.bool,
  transparentPadding: PropTypes.bool,
};

NonScrollable.defaultProps = {
  transparent: false,
  sx: {},
  transparentPadding: false,
};

export default NonScrollable;
