export const truncate = (text, length) => {
  if (text) {
    return text.length > length ? `${text.substr(0, length - 1)}...` : text;
  }
  return '';
};

export const formatStringForCSV = (text) => {
  let result;
  if (text) {
      result = text.replace(/(\r\n|\n|\r|\s+|\t|&nbsp;)/gm,' ');
      result = result.replace(/"/g, '""');
      result = result.replace(/ +(?= )/g,'');
  } else {
      result = '';
  }
  return result;
}

export const foundKeywords = (text, keywords) => {
  const tokens = keywords?.toLowerCase().split(' ');
  if (text) {
    let tokensFound = 0;
    for (let i = 0; i < tokens?.length; i += 1) {
      const token = tokens[i];
      if (text.toLowerCase().includes(token)) {
        tokensFound += 1;
      }
    }
    return tokensFound === (tokens ? tokens?.length : 0);
  }
  return true;
};

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export const titleCase = (str) => {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
  }
  return splitStr.join(' '); 
}

export const validEmailAddress = (email) => {
  if (!email || (email && email.length === 0)) {
    return true;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

