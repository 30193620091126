import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';

const Scrollable = ({ children, transparent, onScroll, sx, transparentPadding }) => {
  const theme = useTheme();
  const padding = transparentPadding
    ? theme.spacing(transparentPadding)
    : theme.spacing(theme.contentPaddingSpacing);
  return (
    <Box
      onScroll={(e) => {
        if (onScroll) {
          onScroll(e.target.scrollTop);
        }
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.largeBorderRadius,
        flex: '1',
        overflowY: 'scroll',
        height: '100%',
        bgcolor: transparent ? null : 'greys.white',
        paddingTop: transparent && transparentPadding ? padding : 0,
        paddingRight: transparent && !transparentPadding ? 0 : padding,
        paddingLeft: transparent && !transparentPadding ? 0 : padding,
        paddingBottom: transparent && !transparentPadding ? 0 : padding,
        ...sx,
      }}
      data-cy="scroll"
    >
      {children}
    </Box>
  );
};

Scrollable.propTypes = {
  transparent: PropTypes.bool,
  onScroll: PropTypes.func,
  sx: PropTypes.oneOfType([PropTypes.object]),
  transparentPadding: PropTypes.number,
};

Scrollable.defaultProps = {
  transparent: false,
  onScroll: null,
  sx: null,
  transparentPadding: 0,
};

export default Scrollable;
