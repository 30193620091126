import React, { useState, useEffect, useContext } from 'react';
import { CheckRounded } from '@mui/icons-material';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import PropTypes from 'prop-types';
import {
  Grid,
  Link,
  InputAdornment,
  TextField,
  Typography,
  Button,
  Box,
  Select,
  MenuItem,
  useTheme,
} from '@mui/material';
import LoadingButton from 'components/LoadingButton/LoadingButton';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import highlightWords from 'highlight-words';
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { RegionDataContext, AuthDataContext } from 'contexts';
import { cloneDeep, uniqWith } from 'lodash';

const AsinLookup = ({
  searchResults,
  setSearchResults,
  removeAsin,
  maxExceeded,
  onToggleAsin = () => {},
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('featured');
  const [storeId, setStoreId] = useState(null);
  const [searchPagination, setSearchPagination] = useState(null);
  const [existingAsins, setExistingAsins] = useState([]);
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const { regionUrl, regionCurrency } = useContext(RegionDataContext);
  const { region } = useContext(AuthDataContext);
  const theme = useTheme();

  const GET_ASIN_NUMBERS_FOR_ACCOUNT_QUERY = gql`
    query GetAsinNumbersForAccount {
      getAsinsForAccount {
        asin
      }
    }
  `;

  const PRODUCT_SEARCH_QUERY = gql`
    query ProductSearch($search: String!, $page: Int!, $sortBy: String) {
      productSearch(search: $search, page: $page, sortBy: $sortBy) {
        searchResults {
          asin
          title
          image
          price
          sponsored
        }
        totalPages
        totalResults
        currentPage
        storeId
      }
    }
  `;

  const setHighlightChunks = (searchResultsToChunk, query) => {
    const results = cloneDeep(searchResultsToChunk);
    for (let i = 0; i < results.length; i += 1) {
      const searchResult = results[i];
      const chunks = highlightWords({
        text: searchResult.title || '(No Title Available)',
        query,
        matchExactly: false,
        clipBy: false,
      });
      searchResult.chunks = chunks;
    }
    return results;
  };

  const processSearchData = (data) => {
    setSearched(true);
    const productSearchResult = data;
    setStoreId(productSearchResult?.storeId);
    if (currentPageNum === 1) {
      // rainforest api result sets cannot be trusted
      let results = cloneDeep(productSearchResult?.searchResults);
      results = results.reduce((acc, sr) => {
        if (!acc.find((newSr) => newSr.asin === sr.asin)) {
          acc.push(sr);
        }
        return acc;
      }, []);
      results = setHighlightChunks(results, search);
      const formattedResults = results.map((result) => ({
        ...result,
        selected: false,
      }));

      setSearchResults(formattedResults);
    } else {
      // rainforest api result sets cannot be trusted
      
      // dedupe the new results, sometimes dupes appear in there
      let newResults = productSearchResult?.searchResults ?? [];
      newResults = uniqWith(newResults, (x, y) => x.asin === y.asin);
      // make sure to also exclude results that are in the previous set

      const moreResults = newResults.filter(
        (newResult) =>
          !searchResults.find((existingResult) => existingResult.asin === newResult.asin),
      );

      let results = [...searchResults, ...moreResults];
      results = setHighlightChunks(results, search);

      const formattedResults = results.map((result) => ({
        ...result,
        selected: false,
      }));
      setSearchResults(formattedResults);
    }

    const { currentPage, totalPages, totalResults } = productSearchResult;
    if (currentPage && totalPages && totalResults && currentPage < totalPages) {
      setSearchPagination({ currentPage, totalPages, totalResults });
    } else {
      setSearchPagination({ totalResults: productSearchResult?.searchResults?.length });
    }
  };

  const [productSearch] = useLazyQuery(PRODUCT_SEARCH_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: (e) => {
      console.log(e);
    },
    onCompleted: (data) => {
      if (data) {
        processSearchData(data.productSearch);
      }
    },
  });

  const { data } = useQuery(GET_ASIN_NUMBERS_FOR_ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    if (data) {
      setExistingAsins(cloneDeep(data.getAsinsForAccount));
    }
  }, [data]);

  // validate form values
  const validateForm = ({ validateSearch }) => {
    const errors = [];
    if (!validateSearch) {
      errors.push({ search: 'Brand Product Name Required' });
    }
    if (validateSearch.length > 100) {
      errors.push({ search: 'Brand Product Name must be 100 characters or less.' });
    }
    return errors;
  };

  const searchProducts = async ({ pageNum = 1 }) => {
    setCurrentPageNum(pageNum);
    // clear results if pagenum =1
    if (pageNum === 1) setSearchResults([]);
    const errors = validateForm({ validateSearch: search });
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        await productSearch({
          variables: {
            search,
            page: pageNum,
            sortBy,
          },
        });
        setLoading(false);
      } catch (err) {
        setFormErrors({ formError: err.message });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid container item xs={12}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box component="p" sx={{ fontSize: '14px' }}>
          Enter product name, keywords, or your Store ID ...{' '}
          <i>(Store ID lookup may take a few minutes)</i>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <TextField
            disabled={loading || maxExceeded}
            autoFocus
            required
            fullWidth
            id="search"
            type="text"
            placeholder="e.g. Titleist TruFeel Golf Balls or A2C7AAA9-148F-4FD5-9D52-C4946527CBC2"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            error={Boolean(formErrors.search)}
            helperText={formErrors.search}
            form="lookup-form"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mb: '1px' }}>
                  <SearchIcon fill={theme.palette.primary.main} />
                </InputAdornment>
              ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              form: 'lookup-form',
              autoFocus: true,
            }}
          />
          <LoadingButton
            sx={{ height: 'auto' }}
            loading={loading}
            type="submit"
            form="lookup-form"
            disabled={maxExceeded}
          >
            Lookup
          </LoadingButton>
        </Box>
      </Box>
      <Select
        sx={{ mt: 2, width: '100%' }}
        value={sortBy}
        onChange={(e) => setSortBy(e?.target?.value)}
      >
        <MenuItem value="featured">Search Sort: Featured</MenuItem>
        <MenuItem value="price_low_to_high">Search Sort: Price - Low to High</MenuItem>
        <MenuItem value="price_high_to_low">Search Sort: Price - High to Low</MenuItem>
        <MenuItem value="average_review">Search Sort: Avg. Customer Review</MenuItem>
        <MenuItem value="most_recent">Search Sort: Newest Arrivals</MenuItem>
      </Select>
      <form
        id="lookup-form"
        onSubmit={async (e) => {
          e.preventDefault();
          searchProducts({ pageNum: 1 });
        }}
      >
        {formErrors.formError && <Box>{formErrors.formError}</Box>}
        {!loading && searchResults && searchResults.length === 0 && searched && (
          <Grid container spacing={0}>
            <Box sx={{ fontSize: '15px', fontWeight: 700, mt: 2, ml: 1 }}>
              No Results
            </Box>
          </Grid>
        )}
        {searchResults && searchResults.length > 0 && (
          <Grid container spacing={0}>
            <h4>
              {searchPagination?.totalResults === 1
                ? '1 Result'
                : `${searchPagination?.totalResults} Results (Listed as presented on Amazon)`}
            </h4>
            <Grid container spacing={4} sx={{ color: 'black' }}>
              <Grid item xs={storeId ? 7 : 5}>
                <Typography variant="body1" sx={{ fontSize: '14px', color: 'greys.grey' }}>
                  Product Name
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" sx={{ fontSize: '14px', color: 'greys.grey' }}>
                  ASIN
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" sx={{ fontSize: '14px', color: 'greys.grey' }}>
                  Price
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ overflow: 'scroll', maxHeight: '30vh', pt: 3, px: '4px', pb: '4px' }}>
              {searchResults &&
                searchResults.map((searchResult) => (
                  <Grid
                    key={searchResult.asin}
                    container
                    spacing={4}
                    sx={{
                      bgcolor: 'greys.white',
                      border: `1px solid ${theme.palette.greys.lightGrey}`,
                      alignItems: 'center',
                      pb: 7,
                      px: 1,
                    }}
                  >
                    {!storeId && (
                      <Grid item xs={1}>
                        {searchResult.image && (
                          <Box
                            component="img"
                            sx={{ width: '35px', height: '35px', borderRadius: '50%' }}
                            src={searchResult.image}
                            alt={searchResult.title}
                          />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={storeId ? 6 : 4}>
                      <Box
                        sx={{
                          fontFamily: theme.typography.fontFamily,
                          fontSize: '13px',
                          fontWeight: 'bold',
                        }}
                      >
                        <Link
                          href={`${regionUrl}/dp/${searchResult.asin}`}
                          target="_blank"
                          underline="hover"
                        >
                          <Box sx={{ color: 'greys.black' }}>
                            {searchResult.sponsored && <em>(Sponsored)&nbsp;</em>}
                          </Box>
                          {searchResult.chunks.map(({ text, match, key }) =>
                            match ? (
                              <Box
                                component="span"
                                sx={{ bgcolor: '#ffffe0', color: 'greys.black' }}
                                key={key}
                              >
                                {text}
                              </Box>
                            ) : (
                              <Box component="span" key={key} sx={{ color: 'greys.black' }}>
                                {text}
                              </Box>
                            ),
                          )}
                        </Link>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography sx={{ fontSize: '13px' }}>{searchResult.asin}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography sx={{ fontSize: '13px' }}>
                        {Intl.NumberFormat(region, {
                          style: 'currency',
                          currency: regionCurrency,
                        }).format(searchResult.price)}
                      </Typography>
                    </Grid>
                    {!storeId && (
                      <Grid item xs={1}>
                        {existingAsins.filter((e) => e.asin === searchResult.asin).length === 0 && (
                          <>
                            <Button
                              disabled={maxExceeded && !searchResult.selected}
                              sx={[
                                searchResult.selected && {
                                  '&.Mui-disabled': {
                                    ml: '-100px',
                                    bgcolor: 'greys.grey',
                                  },
                                  ml: '-10px',
                                  bgcolor: 'greys.white',
                                  border: `1px solid ${theme.palette.greys.grey}`,
                                  '&:hover': {
                                    bgcolor: 'primary.main',
                                    border: 'none',
                                  },
                                  '&:hover svg': {
                                    color: 'greys.white',
                                  },
                                },
                                !searchResult.selected && {
                                  ml: '-10px',
                                  bgcolor: 'greys.white',
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  color: 'primary.main',
                                  '&:hover': {
                                    bgcolor: 'primary.main',
                                    color: 'greys.white',
                                  },
                                },
                              ]}
                              onClick={() => {
                                if (searchResult.selected) {
                                  removeAsin(searchResult);
                                } else {
                                  onToggleAsin(searchResult);
                                }
                              }}
                            >
                              {searchResult.selected ? (
                                <CheckRounded sx={{ color: 'chips.green.color' }} />
                              ) : (
                                'Add'
                              )}
                            </Button>
                          </>
                        )}
                        {existingAsins.filter((e) => e.asin === searchResult.asin).length > 0 && (
                          <Button
                            sx={{
                              ml: '-10px',
                              bgcolor: 'greys.white',
                              border: `1px solid ${theme.palette.primary.main}`,
                              color: 'primary.main',
                              '&:hover': {
                                bgcolor: 'primary.main',
                                color: 'greys.white',
                              },
                              '&.Mui-disabled': {
                                bgcolor: 'greys.grey',
                                border: `1px solid ${theme.palette.greys.grey}`,
                              },
                            }}
                            disabled
                          >
                            <CheckRounded sx={{ fill: 'greys.white' }} />
                          </Button>
                        )}
                      </Grid>
                    )}
                  </Grid>
                ))}
              {searchPagination && searchPagination.currentPage && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  sx={{ mt: '6px' }}
                >
                  <LoadingButton
                    loading={loading}
                    variant="outlined"
                    endIcon={<MoreHorizIcon />}
                    sx={{ p: '0px 25px' }}
                    onClick={() => {
                      const pageNum = Number(searchPagination.currentPage) + 1;
                      searchProducts({ pageNum });
                    }}
                  >
                    Load more
                  </LoadingButton>
                </Grid>
              )}
            </Box>
          </Grid>
        )}
      </form>
    </Grid>
  );
};

AsinLookup.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSearchResults: PropTypes.func.isRequired,
  removeAsin: PropTypes.func.isRequired,
  maxExceeded: PropTypes.bool.isRequired,
};

export default AsinLookup;
