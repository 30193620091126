import React, { useState, useContext, useEffect } from 'react';
import {
  Menu,
  Button,
  Box,
  useTheme,
  Typography,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { Loading, ConfirmationDialog } from 'components';
import { ToastDataContext, AuthDataContext } from 'contexts';
import { DriveFileMoveRounded } from '@mui/icons-material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

const MoveTierMenu = ({
  asin,
  sx,
  onMovedItem
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [availableTiers, setAvailableTiers] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [busy, setBusy] = useState(null);
  const { setToast } = useContext(ToastDataContext);
  const { account, hasFeatureEnabled, updateAccount } = useContext(AuthDataContext);
  const [tiersMenuAnchorEl, setTiersMenuAnchorEl] = useState(null);
  const [moveTiersConfirmationCategory, setMoveTiersConfirmationCategory] = useState(null);
  const [movingAsins, setMovingAsins] = useState(false);
  const theme = useTheme();

  const processData = (dataToProcess) => {
    if (dataToProcess) {
      const tiersList = dataToProcess.getTiersForAccount;
      const filteredTiers = [];
      const filteredTiersObjects = [];
      for (let i = 0; i < tiersList.length; i += 1) {
        if (
          tiersList[i].tier !== asin.tier &&
          tiersList[i].asinCount < tiersList[i].slots &&
          tiersList[i].tier !== 'daily' &&
          tiersList[i].slots - tiersList[i].asinCount >= 1
        ) {
          filteredTiers.push(tiersList[i].tier);
          filteredTiersObjects.push(tiersList[i]);
        }
      }
      setAvailableTiers(filteredTiersObjects);
      setAvailableCategories(dataToProcess.getCategoryList);
      setLoading(false);
    }
  }

  const GET_TIERS_FOR_ACCOUNT_QUERY = gql`
    query GetTiersForAccount($accountId: Int) {
      getTiersForAccount(accountId: $accountId) {
        id
        tier
        description
        slots
        asinCount
      }
      getCategoryList {
        id
        name
        tier
      }
    }
  `;

  const [fetchData] = useLazyQuery(GET_TIERS_FOR_ACCOUNT_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { accountId: account.id },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const MOVE_ASINS_MUTATION = gql`
    mutation MoveAsins($categoryId: ID!, $asins: [String]!) {
      moveAsins(categoryId: $categoryId, asins: $asins)
    }
  `;
  const [moveAsinsMutation] = useMutation(MOVE_ASINS_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
      setMoveTiersConfirmationCategory(null);
      setMovingAsins(false);
      setBusy(false);
    },
  });

  useEffect(() => {
    if (anchorEl) {
      setLoading(true);
      fetchData();
      setBusy(false);
    }
  }, [anchorEl]);

  useEffect(() => {
    setBusy(false);
  }, [asin]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const moveToCategory = (cid) => {
    const asinArray = [asin.asin]
    const asinIdArray = [asin.id];
    setMovingAsins(true);
    moveAsinsMutation({
      variables: { categoryId: cid, asins: asinArray },
      update(cache) {
        for (let i = 0; i < asinIdArray.length; i += 1) {
          const asinId = asinIdArray[i];
          const normalizedId = cache.identify({ id: asinId, __typename: 'CategoryASIN' });
          cache.evict({ id: normalizedId });
        }
        cache.gc();
      },
      onCompleted: () => {
        setMoveTiersConfirmationCategory(null);
        setMovingAsins(false);
        updateAccount();
        if (onMovedItem) {
          onMovedItem();
        }
      },
    });
  };

  const handleCategoryMenuClose = (categoryId) => {
    setTiersMenuAnchorEl(null);
    setAnchorEl(null);
    if (categoryId) {
      setBusy(true);
      moveToCategory(categoryId);
    }
  };

  if (!hasFeatureEnabled('tiers') || asin?.tier !== 'daily') {
    return null;
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '30px',
          maxHeight: '30px',
          ...sx,
        }}
      >
        {busy && <Loading small />}
        {!busy && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button size="small" onClick={handleMenu}>
              Upgrade
            </Button>
          </Box>
        )}
      </Box>
      {Boolean(anchorEl) && (
        <Menu
          disabled={loading}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          {loading &&
            <Box>
              <MenuItem
                disabled
                sx={{
                  minWidth: '200px',
                  display: 'flex',
                  gap: 1,
                  bgcolor: 'greys.lightGrey',
                  borderBottom: `5px solid ${theme.palette.greys.lightGrey}`,
                }}
              >
                <Typography data-cy="asin_bulk_move_tier" sx={{ color: 'greys.black' }}>
                  Move to Tier...
                </Typography>
                <DriveFileMoveRounded sx={{ fill: theme.palette.greys.black }} />
              </MenuItem>
              <Box sx={{ py: 1 }}>
                <Loading small />
              </Box>
            </Box>
          }
          {!loading && availableTiers.length <= 0 && (
            <Tooltip
              arrow
              title={
                <Typography>
                  There are not enough slots in the other tiers to move these ASINs.
                  <br /> Free up more slots or select fewer ASINs.
                </Typography>
              }
            >
              <Box>
                <MenuItem
                  disabled
                  sx={{
                    minWidth: '200px',
                    display: 'flex',
                    gap: 1,
                    bgcolor: 'greys.lightGrey',
                    borderBottom: `5px solid ${theme.palette.greys.lightGrey}`,
                  }}
                >
                  <Typography data-cy="asin_bulk_move_tier" sx={{ color: 'greys.black' }}>
                    Move to Tier...
                  </Typography>
                  <DriveFileMoveRounded sx={{ fill: theme.palette.greys.black }} />
                </MenuItem>
              </Box>
            </Tooltip>
          )}
          {!loading && availableTiers.length > 0 && (
            <MenuItem
              disabled
              sx={{
                minWidth: '200px',
                display: 'flex',
                gap: 1,
                bgcolor: 'greys.lightGrey',
                borderBottom: `5px solid ${theme.palette.greys.lightGrey}`,
              }}
            >
              <Typography data-cy="asin_bulk_move_tier" sx={{ color: 'greys.black' }}>
                Move to Tier...
              </Typography>
              <DriveFileMoveRounded sx={{ fill: theme.palette.greys.black }} />
            </MenuItem>
          )}
          {!loading && availableTiers.map((t) => (
            <Box key={t.tier}>
              <MenuItem disabled>
                <b>{t.description}</b>
              </MenuItem>
              <Box
                sx={{
                  width: '100%',
                  m: 0,
                  p: 0,
                  borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                }}
              />
              {availableCategories
                .filter((a) => a.tier === t.tier)
                .map((c) => (
                  <MenuItem key={c.id} onClick={() => setMoveTiersConfirmationCategory(c)}>
                    {c.name}
                  </MenuItem>
                ))}
            </Box>
          ))}
        </Menu>
      )}
      {Boolean(tiersMenuAnchorEl) && (
        <Menu
          anchorEl={tiersMenuAnchorEl}
          open={Boolean(tiersMenuAnchorEl)}
          onClose={() => setTiersMenuAnchorEl(null)}
          PaperProps={{
            stye: {
              maxHeight: '30vh',
            },
          }}
        >
          {availableTiers.map((t) => (
            <Box key={t.name}>
              <MenuItem disabled>
                <b>{t.description}</b>
              </MenuItem>
              <Box
                sx={{
                  width: '100%',
                  m: 0,
                  p: 0,
                  borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
                }}
              />
              {availableCategories
                .filter((a) => a.tier === t.tier)
                .map((c) => (
                  <MenuItem key={c.id} onClick={() => setMoveTiersConfirmationCategory(c)}>
                    {c.name}
                  </MenuItem>
                ))}
            </Box>
          ))}
        </Menu>
      )}
      {moveTiersConfirmationCategory !== null && (
        <ConfirmationDialog
          open={moveTiersConfirmationCategory !== null}
          title="Move ASIN to new tier"
          message={
            <Typography>
              Are you sure you wish to move the asin to a new tier?
              <br />
              <br />
              Updated ASIN data may take up to 24 hours to show in the app.
            </Typography>
          }
          busy={movingAsins}
          okTitle="Move ASIN"
          destructive
          onClose={(confirmed) => {
            if (confirmed) {
              handleCategoryMenuClose(moveTiersConfirmationCategory.id);
            } else {
              setMoveTiersConfirmationCategory(null);
            }
          }}
        />
      )}
    </Box>
  );
};

export default MoveTierMenu;
