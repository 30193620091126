import React, { useState, useEffect, useContext } from 'react';
import { Content, Top, Scrollable, Header, Loading, ConfirmationDialog, MessageDialog } from 'components';
import {
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableSortLabel,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import { ReactComponent as ClearIcon } from 'assets/clear.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { AuthDataContext, ToastDataContext } from 'contexts';
import InfiniteScroll from 'react-infinite-scroller';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import TenantRow from './TenantRow';
import UpdateTenant from './UpdateTenant';

const Tenants = () => {
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [tenants, setTenants] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const { setToast } = useContext(ToastDataContext);
  const [searchText, setSearchText] = useState('');
  const scrollPageLength = 20;
  const [limit, setLimit] = useState(scrollPageLength);
  const [message, setMessage] = useState(null);
  const [orderBy, setOrderBy] = useState('title');
  const [sortOrder, setOrder] = useState('asc');
  const theme = useTheme();
  const history = useHistory();
  const { account, hasPermission } = useContext(AuthDataContext);
  const [showAddTenant, setShowAddTenant] = useState(false);
  const [showEditTenant, setShowEditTenant] = useState(null);
  const [deleteConfirmationTenant, setDeleteConfirmationTenant] = useState(null);

  const headCells = [
    { id: 'title', label: 'Title', numeric: false, sortable: true, width: '45%', align: 'left' },
    { id: 'tenant', label: 'Path', numeric: true, sortable: false, width: '15%', align: 'left' },
    { id: 'features', label: 'Features', numeric: false, sortable: false, width: '15%', align: 'left' },
    { id: 'clientCount', label: 'Clients', numeric: true, sortable: false, width: '10%', align: 'center' },
    { id: 'asinCount', label: 'ASINs', numeric: true, sortable: false, width: '10%', align: 'center' },
    { id: 'actions', label: '', numeric: false, sortable: false, width: '5%', align: 'right' },
  ];

  const sortHandler = (headCell, dataToProcess, order) => {
    if (!headCell.sortable) {
      return;
    }
    const isAsc = orderBy === headCell.id && order === 'asc';
    setOrder(isAsc ? 'asc' : 'desc');
    setOrderBy(headCell.id);
    const sorted = [].concat(dataToProcess).sort((a, b) => {
      if (headCell.numeric) {
        if (!isAsc) {
          return b[headCell.id] - a[headCell.id];
        }
        return a[headCell.id] - b[headCell.id];
      }
      if (!isAsc) {
        return b[headCell.id].localeCompare(a[headCell.id]);
      }
      return a[headCell.id].localeCompare(b[headCell.id]);
    });
    setFiltered(sorted);
  };

  const handleFilter = (dataToFilter, searchKeywords) => {
    const searchData = dataToFilter.filter((seller) => {
      const tokens = searchKeywords?.toLowerCase().split(' ');
      let tokensFound = 0;
      for (let i = 0; i < tokens.length; i += 1) {
        const token = tokens[i];
        if (seller.objString.toLowerCase().includes(token)) {
          tokensFound += 1;
        }
      }
      return tokensFound === (tokens ? tokens?.length : 0);
    });
    setFiltered(searchData);
    sortHandler(headCells[0], searchData, 'asc');
  };

  const processData = (dataToProcess) => {
    const noIPSecure = dataToProcess.getTenants;
    const tenantsWithString = noIPSecure.map((t) => ({
      ...t,
      objString: `${t.title} ${t.path}`,
    }));
    setTenants(tenantsWithString);
    setFiltered(tenantsWithString);
    sortHandler(headCells[0], tenantsWithString, 'asc');
    handleFilter(tenantsWithString, searchText);
    setLoading(false);
  };

  const GET_TENANTS_QUERY = gql`
    query GetTenants {
      getTenants {
        id
        tenant
        title
        buttonColor
        navColor
        loginLogo
        navLogo
        clientCount
        asinCount
        disabled
        federatedDomain
        federatedProvider
        federatedShortName
        configuredFeatures {
          id
          name
          description
        }
        configuredRegions {
          id
          currency
          baseUrl
          iso
          name
          nativeName
        }
        permittedDomains {
          id
          domain
        }
      }
    }
  `;

  const { data, refetch } = useQuery(GET_TENANTS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: typeof account !== 'object',
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPSERT_TENANT_MUTATION = gql`
    mutation UpsertTenant($id: ID, $disabled: Boolean) {
      upsertTenant(id: $id, disabled: $disabled)
    }
  `;

  const [upsertTenant] = useMutation(UPSERT_TENANT_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const DELETE_TENANT_MUTATION = gql`
    mutation DeleteTenant($id: ID!) {
      deleteTenant(id: $id) 
    }
  `;

  const [deleteTenant] = useMutation(DELETE_TENANT_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const onSearch = (text) => {
    setSearchText(text);
    handleFilter(tenants, text);
  };

  useEffect(() => {
    if (data) {
      processData(data);
    }
  }, [data]);

  const loadMore = () => {
    if (limit < filtered.length) {
      let newLimit = limit + scrollPageLength;
      if (newLimit > filtered.length) {
        newLimit = filtered.length;
      }
      setLimit(newLimit);
    }
  };

  const handleDisableTenant = (t) => {
    upsertTenant({
      variables: {
        id: t.id,
        disabled: true,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: 'Tenant disabled' });
        refetch();
      },
    });
  }

  const handleEnableTenant = (t) => {
    upsertTenant({
      variables: {
        id: t.id,
        disabled: false,
      },
      onCompleted: () => {
        setToast({ type: 'success', message: 'Tenant enabled' });
        refetch();
      },
    });
  }

  const performDeleteTenant = (t) => {
    setDeleting(true);
    deleteTenant({
      variables: {
        id: t.id,
      },
      onCompleted: () => {
        refetch();
        setDeleting(false);
        setDeleteConfirmationTenant(null);
      },
    });
  }

  if (!hasPermission('tenantManagement')) {
    return (<Box />);
  }

  const handleDeleteTenant = (t) => {
    if (t.permittedDomains?.length > 0) {
      setMessage({title: 'Error', text: 'Please remove Email Domains from this tenant first'});
    } else {
      setDeleteConfirmationTenant(t);
    }
  }

  return (
    <Content>
      <Top>
        <Header title="Tenants" />
      </Top>
      <Scrollable data-cy="seller_scroll">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <TextField
            disabled={loading}
            type="text"
            onChange={(event) => onSearch(event.target.value)}
            placeholder="Search"
            data-cy="sellers_search_bar"
            value={searchText}
            sx={{ width: '30ch' }}
            InputProps={{
              sx: {
                pl: 1,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fill={theme.palette.greys.silver} />
                </InputAdornment>
              ),
              endAdornment: searchText ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      onSearch('');
                    }}
                    size="large"
                  >
                    <ClearIcon fill={theme.palette.greys.silver} />
                  </IconButton>
                </InputAdornment>
              ) : (
                ''
              ),
            }}
          />
          <Button
            onClick={() => {
              setShowAddTenant(true);
            }}
            data-cy="clients_add_client"
          >
            Add Tenant
          </Button>
        </Box>
        {loading && <Loading />}
        {!loading && (
          <Box sx={{ overflow: 'scroll', height: '100%' }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={true || false}
              loader={limit < filtered.length && <Loading key={0} />}
              useWindow={false}
            >
              <TableContainer data-cy="sellers_scroll" sx={{ overflowX: 'initial' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? sortOrder : false}
                          width={headCell.width}
                          align={headCell.align}
                        >
                          <TableSortLabel
                            hideSortIcon={!headCell.sortable}
                            data-cy={headCell.label}
                            active={headCell.sortable && orderBy === headCell.id}
                            direction={orderBy === headCell.id ? sortOrder : 'asc'}
                            onClick={() => {
                              sortHandler(headCell, filtered, sortOrder === 'asc' ? 'desc' : 'asc');
                            }}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody data-cy="sellers_table">
                    {filtered.slice(0, limit).map((tenant, index) => (
                      <TenantRow
                        key={tenant.id}
                        tenant={tenant}
                        index={index}
                        onShowTenant={(t) => {
                          history.push(`/tenants/${t.id}`);
                        }}
                        onEditTenant={t => {
                          setShowEditTenant(t);
                        }}
                        onDisableTenant={handleDisableTenant}
                        onEnableTenant={handleEnableTenant}
                        onDeleteTenant={handleDeleteTenant}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </Box>
        )}
      </Scrollable>
      {
        showAddTenant && (
          <UpdateTenant
            isOpen
            onClose={() => setShowAddTenant(false)}
            onDone={() => {
              setShowAddTenant(false)
              refetch();
            }}
          />
        )
      }
      {
        showEditTenant && (
          <UpdateTenant
            tenant={showEditTenant}
            isOpen
            onClose={() => setShowEditTenant(false)}
            onDone={() => {
              setShowEditTenant(false)
              refetch();
            }}
          />
        )
      }
      {
        deleteConfirmationTenant && (
          <ConfirmationDialog
            open
            busy={deleting}
            title="Delete Tenant"
            message={(
              <Box sx={{ mt: 2 }}>
                Are you sure you wish to delete <b>{deleteConfirmationTenant?.title || deleteConfirmationTenant?.tenant}</b> and all its clients?<br />
                <b>THIS CANNOT BE UNDONE.</b><br />
                This may take several minutes.
              </Box>
            )}
            okTitle="Delete"
            destructive
            confirmDestructive="delete permanently"
            onClose={(confirmed) => {
              if (confirmed) {
                performDeleteTenant(deleteConfirmationTenant);
              } else {
                setDeleteConfirmationTenant(null);
              }
            }}
          />
        )
      }
      {message !== null && (
        <MessageDialog
          open
          title={message.title}
          message={message.text}
          okTitle="OK"
          destructive
          onClose={() => {
            setMessage(null);
          }}
        />
      )}
    </Content>
  );
};

export default Tenants;
