import React, { useState, useContext } from 'react';
import {
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  IconButton,
  Box,
  Menu,
  MenuItem,
  styled,
  tableCellClasses,
  useTheme,
} from '@mui/material';
import { Loading, Switch } from 'components';
import { AuthDataContext, ToastDataContext } from 'contexts';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as MoreHorizIcon } from 'assets/more-horizon.svg';
import { gql, useMutation, useQuery } from '@apollo/client';
import ThresholdDialog from './ThresholdDialog';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderBottom: 'none',
  borderTop: 'none',
  borderLeft: `1px solid ${theme.palette.greys.lightGrey}`,
  borderRight: `1px solid ${theme.palette.greys.lightGrey}`,
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.greys.lightGrey}`,
  },
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.greys.lightGrey}`,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.greys.white,
    color: theme.palette.greys.silver,
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.greys.silver,
    borderWidth: 0,
  },
}));

const ActionsMenu = ({ onEdit, item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();

  return (
    <>
      <IconButton size="small" onClick={handleMenu}>
        <MoreHorizIcon fill={theme.palette.greys.silver} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-cy="notification_edit_threshold"
          onClick={() => {
            handleClose();
            onEdit(item);
          }}
        >
          <EditIcon />
          Edit
        </MenuItem>
      </Menu>
    </>
  );
};

const NotificationRow = ({ rule, onEditRule, onToggleEmail, small }) => (
  <StyledTableRow key={rule.alertRuleTypeId}>
    <StyledTableCell sx={{ py: small ? 1 : 2, mb: small ? 0 : 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography
          variant={small ? '' : 'h4'}
          sx={{ color: 'black', fontWeight: small ? 500 : 600 }}
        >
          {rule.alertRuleType}
        </Typography>
      </Box>
      <Typography
        variant={small ? '' : 'body1'}
        sx={{ fontSize: small ? '12px' : '14px' }}
        data-cy={`alert_description_${rule.alertRuleType}`}
      >
        {rule.alertRuleDescription} {rule.thresholdValue !== null && `(${rule.thresholdValue})`}
      </Typography>
    </StyledTableCell>
    <StyledTableCell align="center" sx={{ py: small ? 1 : 2, mb: small ? 0 : 2 }}>
      <Switch
        data-cy={`notification_switch_${rule.alertRuleType}`}
        checked={rule.emailEnabled}
        onChange={() => {
          onToggleEmail(rule.alertRuleTypeId);
        }}
      />
    </StyledTableCell>
    <StyledTableCell
      align="center"
      data-cy="settings_notifications_actions"
      sx={{ py: small ? 1 : 2, mb: small ? 1 : 2 }}
    >
      {rule.thresholdEnabled && <ActionsMenu onEdit={onEditRule} item={rule} />}
    </StyledTableCell>
  </StyledTableRow>
);

const Notifications = ({ userId = null, accountId = null, onAlertsUpdated }) => {
  const [loading, setLoading] = useState(true);
  const [alertRules, setAlertRules] = useState([]);
  const [standardRules, setStandardRules] = useState([]);
  const [premiumRules, setPremiumRules] = useState([]);
  const [targetAlert, setTargetAlert] = useState({});
  const [editorOpen, setEditorOpen] = useState(false);
  const { setToast } = useContext(ToastDataContext);
  const { user } = useContext(AuthDataContext);

  const processData = (dataToProcess) => {
    const rules = dataToProcess.getAlertRules.concat().filter((r) => r.alertRuleTypeId !== 8);
    const regular = rules.filter((r) => !r.premium && !r.standard);
    const standard = rules.filter((r) => r.standard);
    const premium = rules.filter((r) => r.premium);
    setAlertRules(regular);
    setStandardRules(standard);
    setPremiumRules(premium);
    setLoading(false);
  };

  const GET_ALERT_RULES = gql`
    query GetAlertRules($userId: Int!) {
      getAlertRules(userId: $userId) {
        id
        idString
        thresholdValue
        alertRuleTypeId
        alertRuleType
        alertRuleDescription
        emailEnabled
        thresholdEnabled
        standard
        premium
      }
    }
  `;

  useQuery(GET_ALERT_RULES, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !user && !userId,
    variables: { userId: userId ?? user?.profile?.userId },
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPSERT_ALERT_RULE_MUTATION = gql`
    mutation UpsertAlertRule($alertRule: AlertRuleInput!, $accountId: Int, $userId: Int) {
      upsertAlertRule(alertRule: $alertRule, accountId: $accountId, userId: $userId) {
        id
        alertRuleTypeId
        emailEnabled
        thresholdValue
      }
    }
  `;

  const [upsertAlertRuleMutation] = useMutation(UPSERT_ALERT_RULE_MUTATION, {
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const toggleEmail = ({ id, standard = false, premium = false }) => {
    let rules = alertRules;
    if (standard) {
      rules = standardRules;
    } else if (premium) {
      rules = premiumRules;
    }
    const index = rules.map((e) => e.alertRuleTypeId).indexOf(id);
    let modifiedRules = JSON.parse(JSON.stringify(rules));
    modifiedRules[index].emailEnabled = !modifiedRules[index].emailEnabled;
    const changedRule = modifiedRules[index];
    const thresholdValue = parseFloat(changedRule.thresholdValue);
    if (Number.isNaN(thresholdValue) && changedRule.thresholdValue !== null) {
      setToast({
        type: 'error',
        message: 'Invalid threshold value. Please tap the edit button and update the threshold.',
      });
      return;
    }
    upsertAlertRuleMutation({
      variables: {
        accountId,
        userId,
        alertRule: {
          id: changedRule.id,
          thresholdValue,
          emailEnabled: changedRule.emailEnabled,
          alertRuleTypeId: changedRule.alertRuleTypeId,
          smsEnabled: false,
          pushEnabled: false,
        },
      },
      onCompleted: (result) => {
        modifiedRules = modifiedRules.map((r) => {
          if (r.alertRuleTypeId === result.upsertAlertRule.alertRuleTypeId) {
            return { ...r, id: result.upsertAlertRule.id };
          }
          return r;
        });
        if (!standard && !premium) {
          setAlertRules(modifiedRules);
        } else if (standard) {
          setStandardRules(modifiedRules);
        } else if (premium) {
          setPremiumRules(modifiedRules);
        }
        if (onAlertsUpdated) {
          onAlertsUpdated();
        }
      },
    });
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <TableContainer>
          <Table size={userId ? 'small' : 'medium'}>
            {userId === null && (
              <TableHead>
                <TableRow
                  sx={{ borderStyle: 'solid', borderColor: 'greys.lightGrey', borderWidth: 1 }}
                >
                  <StyledTableCell width="85%" sx={{ py: userId ? 0 : 3 }}>
                    <Typography
                      sx={{ color: 'black' }}
                      variant={userId ? '' : 'h3'}
                      data-cy="settings_email_header"
                    >
                      Email Alerts {userId ? '' : 'Center'}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="10%">
                    <Typography variant={userId ? '' : 'h4'}>Enabled</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center" width="5%" />
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {alertRules &&
                alertRules.length > 0 &&
                alertRules.map((rule, index) => (
                  <NotificationRow
                    small={userId != null}
                    key={rule.id ?? `def-${index}`}
                    rule={rule}
                    onToggleEmail={(id) => toggleEmail({ id })}
                    onEditRule={(r) => {
                      setTargetAlert(r);
                      setEditorOpen(true);
                    }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {editorOpen && (
        <ThresholdDialog
          isOpen={editorOpen}
          setEditorOpen={setEditorOpen}
          alertRules={alertRules}
          setAlertRules={setAlertRules}
          targetAlert={targetAlert}
          setTargetAlert={(val) => {
            setTargetAlert(val);
            if (onAlertsUpdated) {
              onAlertsUpdated();
            }
          }}
          onClose={() => {
            setEditorOpen(false);
          }}
        />
      )}
    </>
  );
};

export default Notifications;
